<template>
  <LoginLayout>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <form>
            <h2 class="text-center my-4">{{ pageTitle }}</h2>
            <h3 class="text-center my-4 text--white">{{ pageSubTitle }}</h3>
            <v-card max-width="300" class="mx-auto" elevation="6">
              <v-card-text>
                <div class="p-2">
                  <v-text-field
                    v-model="username"
                    :error-messages="usernameErrors"
                    label="Username/Email"
                    required
                    @input="$v.username.$touch()"
                    @blur="$v.username.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    :error-messages="passwordErrors"
                    label="Password"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  class="mr-4"
                  @click="submit"
                  block
                  :color="$store.state.appThemeColor"
                  dark
                >
                  Login
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn text link :to="{ name: 'Forget' }" block
                  >Lupa password?</v-btn
                >
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text link :to="{ name: 'ActivateAccount' }" block
                  >Aktivasi Akun</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </v-container>
  </LoginLayout>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import LoginLayout from "@/layouts/LoginLayout.vue";

export default {
  name: "Login",

  components: { LoginLayout },

  mixins: [ validationMixin ],

  validations: {
    username: { required },
    password: { required },
  },

  data: () => ({
    show1: false,
    username: "",
    password: "",
    rememberMe: false,
    pageTitle: "Parent Portal",
    pageSubTitle: "Login",
  }),

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required &&
        errors.push("Username/Email tidak boleh kosong.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password tidak boleh kosong.");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("username", this.username);
        formData.append("password", this.password);

        this.$store.state.showLoadingDialog = true;
        this.$axios
          .post("login", formData)
          .then((res) => {
            this.$store.state.showLoadingDialog = false;
            if (res.data.status == "success") {
              this.$store.state.apiToken = res.data.data.token;
              this.$store.state.loginInfo.username = res.data.data.username;
              this.$store.state.loginInfo.firstName = res.data.data.firstname;
              this.$store.state.loginInfo.lastName = res.data.data.lastname;
              this.$store.state.loginInfo.profileImage = res.data.data.user_img;
              this.$store.state.loginInfo.email = res.data.data.email;
              this.$store.state.loginInfo.userType = res.data.data.user_type;
              this.$store.state.loginInfo.students = res.data.data.students;

              this.$store.state.isLoggedIn = true;
              // this.$store.state.showLoadingDialog = false;
              this.$router.push("/");
              // window.location.assign("/home");
            } else {
              this.showAlert(res.data.alert, res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.showAlert("error", "Internal Server Error!");
            this.$store.state.showLoadingDialog = false;
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      }
    },
    clear() {
      this.$v.$reset();
      this.username = "";
      this.password = "";
      this.rememberMe = false;
    },
  },

  mounted() {
    document.title = this.pageTitle + ' | ' + this.pageSubTitle
  },
};
</script>

<style scoped>
h2, h3 {
  color: #ffffff;
}
</style>